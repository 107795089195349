import {BlockListResponseType} from './types';

import {AxiosInstance} from 'axios';
import FactoryAxios from '@/Config/Axios/factory.axios';
import {URL} from '@/API/urls';

class AutomatedCampaignsApi {
  readonly api: AxiosInstance;

  constructor(controller: string) {
    this.api = new FactoryAxios(URL.BASE_ADMIN, controller).getInstance();
  }

  async getBlockList(searchValue?: string): Promise<BlockListResponseType[]> {
    return await this.api
      .get(`/block-list`, {
        params: {
          searchValue,
        },
      })
      .then(res => res.data);
  }
  //
  async removeEmailFromBlockList(email: string): Promise<any> {
    return await this.api.delete(`/block-list/${email}`).then(res => res.data);
  }
  //
  async addEmailToBlockList(email: string): Promise<any> {
    return await this.api.post(`/block-list`, {email}).then(res => res);
  }
}
export default AutomatedCampaignsApi;
