import {useCommunityStore} from '@/Store/community/community.selector';
import {useRootStore} from '@/Store/root/root.selector';
import {GET_SOCKET} from '@/WebSockets';
import {useEffect, useState} from 'react';
import io from 'socket.io-client';
import {TRIGGERS} from '../triggers';

const useSocketNotification = () => {
  const [socket, setSocket] = useState<any>(null);
  const {user} = useRootStore().selectors;
  const {setBadges} = useCommunityStore().actions;
  useEffect(() => {
    const socket = io(GET_SOCKET('notifications'));

    socket.on(TRIGGERS.connect, () => {
      console.log('WebSocket connection established.');
      socket.emit('connected', user?.email);
    });

    socket.on(TRIGGERS['leads-count'], (leadsCount: number) =>
      setBadges({badget: 'onboarding', value: leadsCount})
    );

    socket.on(TRIGGERS.disconnect, () => {
      console.log('WebSocket connection closed.');
    });

    setSocket(socket);

    // Return the socket object from the effect
    return () => {
      socket.close();
    };
  }, []);

  // Return the socket object from the hook
  return socket;
};

export default useSocketNotification;
