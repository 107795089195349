class MediaService {
  static videoExt = ['mp4', 'mov'];
  static imageExt = ['jpg', 'bpm', 'png', 'jpeg'];

  static getExt(mediaName?: string) {
    if (!mediaName) return '';
    const ext = mediaName.split('.').pop();
    return !ext ? '' : ext;
  }
  static isMedia(media: string) {
    const ext = this.getExt(media);
    if (!ext) return false;
    return this.videoExt.includes(ext.toLowerCase()) || this.imageExt.includes(ext.toLowerCase());
  }
  static isVideo(media?: string) {
    const ext = this.getExt(media);
    if (!ext) return false;
    return this.videoExt.some(el => ext.includes(el));
  }
  static getImageKitLink(link?: string) {
    if (!link) return;
    const videoPostfix = '?tr=f-mp4,q-100';
    let resLink = link;
    if (link.includes('imagekit') && !link.includes(videoPostfix) && this.isVideo(link)) {
      resLink = resLink + videoPostfix;
    }
    return resLink;
  }
}
export default MediaService;
