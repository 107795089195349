import {WhatsAppTemplates} from '@/API/Sender/sender.types';
import {Box, Div, Grid, Input, Switch, TextArea} from '@/UI';
import {useCallback, useState} from 'react';
import GenericSelect from '../GenericSelect/GenericSelect';

export type SmsWhatsAppPushValues = {
  whatsAppTemplate?: WhatsAppTemplates;
  smsValue?: string;
  pushNotificationValue?: {
    title: string;
    message: string;
  };
};

interface Props {
  incomingValues: SmsWhatsAppPushValues;
  onChange: (value: SmsWhatsAppPushValues) => void;
}
const SmsWhatsAppPush: React.FC<Props> = ({onChange, incomingValues}) => {
  const {whatsAppTemplate, smsValue, pushNotificationValue} = incomingValues;

  const [withWhatsApp, setWithWhatsApp] = useState<boolean>(false);
  const [withSms, setWithSms] = useState<boolean>(false);
  const [withPush, setWithPush] = useState<boolean>(false);

  //Handle change values
  const handleChange = useCallback(
    (prop: keyof SmsWhatsAppPushValues, value: any) => {
      const res = {...incomingValues, [prop]: value};
      onChange(res);
    },
    [incomingValues]
  );
  // Switch handlers
  const handleToggleWhatsapp = useCallback((isTurn: boolean) => {
    if (!isTurn) handleChange('whatsAppTemplate', '');
    setWithWhatsApp(isTurn);
  }, []);
  const handleToggleSms = useCallback((isTurn: boolean) => {
    if (!isTurn) handleChange('smsValue', '');
    setWithSms(isTurn);
  }, []);
  const handleTogglePush = useCallback((isTurn: boolean) => {
    if (!isTurn) handleChange('pushNotificationValue', {title: '', message: ''});
    setWithPush(isTurn);
  }, []);
  return (
    <Box style={{minWidth: 350, maxWidth: 900, margin: '15px 0'}} label="Additional sending">
      <Grid templateColumn="230px 1fr" className="SmsWhatsAppPush">
        <Switch isOn={withWhatsApp} onToggle={handleToggleWhatsapp} option="WhatsApp" />

        <GenericSelect
          label="WhatsApp template"
          optionsType="whatsAppTemplates"
          value={whatsAppTemplate}
          isDisabled={!withWhatsApp}
          onChange={template => handleChange('whatsAppTemplate', template)}
        />

        <Switch isOn={withSms} onToggle={handleToggleSms} option="SMS" />
        <TextArea
          onChange={e => handleChange('smsValue', e.target.value)}
          placeholder="SMS message"
          value={smsValue}
          isDisabled={!withSms}
        />

        <Switch isOn={withPush} onToggle={handleTogglePush} option="Push notification" />
        <Div flexDirection="column">
          <Input
            placeholder="Notification title"
            disabled={!withPush}
            value={pushNotificationValue?.title || ''}
            onChange={e => {
              const res = {
                title: e.target.value,
                message: pushNotificationValue?.message || '',
              };
              handleChange('pushNotificationValue', res);
            }}
          />
          <TextArea
            row={5}
            placeholder="Notifications text"
            value={pushNotificationValue?.message || ''}
            isDisabled={!withPush}
            onChange={e => {
              const res = {
                title: pushNotificationValue?.title || '',
                message: e.target.value,
              };
              handleChange('pushNotificationValue', res);
            }}
          />
        </Div>
      </Grid>
    </Box>
  );
};
export default SmsWhatsAppPush;
