import WithLazy from '@/HOC/WithLazy';
import {lazy} from 'react';
import {ROUTES} from '@/Router/ROUTES';

const Orders = WithLazy(lazy(() => import('@/Website/Pages/Orders/Orders')));
const OrdersLogs = WithLazy(lazy(() => import('@/Website/Pages/OrdersLogs/OrdersLogs')));
const Blocklist = WithLazy(lazy(() => import('@/Website/Pages/BlockList/BlockList')));
const AutomatedOrders = WithLazy(
  lazy(() => import('@/Website/Pages/AutomatedCampaign/AutomatedOrders/AutomatedOrders'))
);
const Providers = WithLazy(lazy(() => import('@/Website/Pages/Providers/Providers')));
const Advertisers = WithLazy(lazy(() => import('@/Website/Pages/Advertisers/Advertisers')));
const TalentsSearcher = WithLazy(lazy(() => import('@/Website/Pages/Searchy/Searchy')));
const SendingLogs = WithLazy(lazy(() => import('@/Website/Pages/SendingLogs/SendingLogs')));
const Influencers = WithLazy(lazy(() => import('@/Website/Pages/Influencers/Influencers')));
const UgcCreators = WithLazy(lazy(() => import('@/Website/Pages/UgcCreators/UgcCreators')));
const Onboarding = WithLazy(lazy(() => import('@/Website/Pages/Onboarding/Onboarding')));
const Dashboard = WithLazy(lazy(() => import('@/Website/Pages/Dashboard/Dashboard')));
const Security = WithLazy(lazy(() => import('@/Website/Pages/Security/Security')));
const CampaignMonitor = WithLazy(lazy(() => import('@/Website/Pages/CampaignMonitor/CampaignMonitor')));
const Approvals = WithLazy(lazy(() => import('@/Website/Pages/Approvals/Approvals')));
const SendingHistory = WithLazy(lazy(() => import('@/Website/Pages/SendingHistory/SendingHistory')));
const Campaigns = WithLazy(lazy(() => import('@/Website/Pages/Campaigns/Campaigns')));

const Notifications = WithLazy(
  lazy(() => import('@/Website/Pages/AutomatedCampaign/AutomatedOnboarding/automatedOnboarding'))
);
const Organizations = WithLazy(lazy(() => import('@/Website/Pages/Organizations/Organizations')));
const Tags = WithLazy(lazy(() => import('@/Website/Pages/Tags/Tags')));
const Categories = WithLazy(lazy(() => import('@/Website/Pages/Categories/Categories')));
const Reminder = WithLazy(lazy(() => import('@/Website/Pages/AutomatedCampaign/Reminder/Reminder')));
const HelpRequests = WithLazy(lazy(() => import('@/Website/Pages/HelpRequests/HelpRequests')));

export const TalentPortfolio = WithLazy(
  lazy(() => import('@/Website/Pages/TalentPortfolio/TalentPortfolio'))
);

export const RoutesValues = [
  {path: ROUTES.categories, Element: Categories, index: false},
  {path: '/', Element: Dashboard, index: true},
  {path: ROUTES.tags, Element: Tags, index: false},
  {path: ROUTES.organizations, Element: Organizations, index: false},
  {path: ROUTES.automatedCampaignNotification, Element: Notifications, index: false},
  {path: ROUTES.campaigns, Element: Campaigns, index: false},
  {path: ROUTES.sendingLogs, Element: SendingHistory, index: false},
  {path: ROUTES.approvals, Element: Approvals, index: false},
  {path: ROUTES.monitor, Element: CampaignMonitor, index: false},
  {path: ROUTES.onboarding, Element: Onboarding, index: false},
  {path: ROUTES.influencers, Element: Influencers, index: false},
  {path: ROUTES.ugcCreators, Element: UgcCreators, index: false},
  {path: ROUTES.emailsLogs, Element: SendingLogs, index: false},
  {path: ROUTES.searchy, Element: TalentsSearcher, index: false},
  {path: ROUTES.providers, Element: Providers, index: false},
  {path: ROUTES.automatedCampaignOrder, Element: AutomatedOrders, index: false},
  {path: ROUTES.blocklist, Element: Blocklist, index: false},
  {path: ROUTES.orders, Element: Orders, index: false},
  {path: ROUTES.ordersLogs, Element: OrdersLogs, index: false},
  {path: ROUTES.reminder, Element: Reminder, index: false},
  {path: ROUTES.security, Element: Security, index: false},
  {path: ROUTES.advertisers, Element: Advertisers, index: false},
  {path: ROUTES.helpRequests, Element: HelpRequests, index: false},
] as Array<{path: string; index: boolean; Element: any}>;
